/**
 * Readmore component.
 * @returns {any}
 */
export default function Readmore() {
  return {
    open: false,
    init() {
      // Silence is golden.
    },
    mounted() {
      // Silence is golden.
    },
    toggleContent() {
      this.$refs.content.style.display = this.open ? 'none' : 'block';
      this.open = !this.open;
      this.$refs.btn.innerHTML = this.open
        ? this.$refs.btn.dataset.labelOpen
        : this.$refs.btn.dataset.labelClose;
    },
  };
}
