import Swiper from 'swiper/bundle';
import Fuse from 'fuse.js';
import { breakpoints } from '../../utils/breakpoints.js';

/**
 * SortableTable.
 * @param {any} props
 * @returns {any}
 */
export default function SortableTable(props) {
  return {
    // Search index
    index: null,
    items: [],
    // Data is used to display the table
    data: [],
    currentPage: 1,
    limitPerPage: props?.limitPerPage ? props.limitPerPage : 9,
    totalPages: 0,
    // A list of columns to show as the table header
    columns: [],
    // The current sorted key
    sortKey: '',
    // Is the sort reversed or not
    isReverseSorted: false,
    // Filters
    // filterable keys and their unique values
    filterables: props?.filterables ? props.filterables : [],
    filters: {},
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`).matches,
    async init() {
      if (this.$refs.swiperCards) {
        this.cardsSlider = new Swiper(this.$refs.swiperCards, {
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        });
      }

      if (props?.apiUrl) {
        fetch(window.maddy.ajax_url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: `action=load_wallet&security=custom_nonce&investor_id=${props.investor_id}`,
        })
          .then((response) => response.json())
          .then((response) => {
            this.data = response;
            this.items = response;

            if (Object.keys(props?.filterables).length > 0) {
              this.filterables = props.filterables;
              this.getFiltersValues();
            }
          });

        return;
      }

      if (this.$refs.json) {
        const textJSON = this.$refs.json.textContent;
        this.data = JSON.parse(textJSON);
        this.items = JSON.parse(textJSON);

        new MutationObserver((mutationList) => {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'childList') {
              this.data = JSON.parse(textJSON);
              this.items = JSON.parse(textJSON);
            }
          });
        }).observe(this.$refs.json, { childList: true, subtree: true });
      }

      if (this.$refs.columns) {
        this.columns = JSON.parse(this.$refs.columns.textContent);
      }

      if (props?.filterables && Object.keys(props?.filterables).length > 0) {
        this.filterables = props.filterables;
        this.getFiltersValues();
      }
    },
    async mounted() {
      await this.init();

      this.index = new Fuse(this.items, {
        keys: [
          'name',
          'description',
          'company.post_title',
          'company.pitch',
          'post_title',
          'last_investment.name',
        ],
      });
      this.totalPages = Math.ceil(this.items.length / this.limitPerPage);
    },
    filterBy() {
      const formData = new FormData(this.$refs.form);
      const filters = Object.entries(Object.fromEntries(formData));
      const filteredFilters = filters
        .filter(([key, value]) => {
          if (key === 'query') {
            return false;
          }

          if (value) {
            return true;
          }

          return false;
        })
        .map(([key, value]) => ({
          [key]: `'${value}`, // Exact search for the value
        }));

      // No filters, reset state
      if (!formData.get('query') && filteredFilters.length === 0) {
        this.data = this.items;
        return this.items;
      }

      // Query search
      const firstResults = this.index.search(formData.get('query'));
      let items = firstResults.map((result) => result.item);

      if (filteredFilters.length === 0) {
        this.data = items;
        return items;
      }

      items = items.length > 0 ? items : this.items;

      if (formData.get('fund_date')) {
        items = items.filter((item) => {
          const result =
            `${new Date(item.fund_date_iso).getFullYear()}` === formData.get('fund_date');
          return result;
        });
      }

      // If more filters, apply them
      const finalIndex = new Fuse(items, {
        useExtendedSearch: true,
        findAllMatches: true,
        isCaseSensitive: true,
        ignoreLocation: true,
        threshold: 0.0,
        keys: Object.keys(Object.fromEntries(formData)).filter((name) => name !== 'query'),
      });

      const finalResults = finalIndex.search({
        $and: filteredFilters,
      });
      const finalItems = finalResults.map((result) => result.item);

      this.data = finalItems;
      console.log(this.data);
      return finalItems;
    },
    sortBy(key) {
      if (this.sortKey === key && this.isReverseSorted) {
        this.resetSort();
        this.isReverseSorted = false;
        this.sortKey = '';
        return;
      }

      if (this.sortKey === key) {
        const newData = this.sortReverse(key);
        this.isReverseSorted = true;
        this.data = newData;
        return;
      }

      this.sortKey = key;
      const newData = this.sort(key);
      this.data = newData;
    },
    sort(key) {
      console.log('sort');
      const data = Array.from(this.data);

      data.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));
      return data;
    },
    sortReverse(key) {
      console.log('sortReverse');
      const reversedArray = this.sort(this.data, key);
      reversedArray.reverse();
      return reversedArray;
    },
    resetSort() {
      console.log('resetSort');
      this.init();
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    next() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    goTo(page) {
      this.currentPage = page;
    },
    paginate(items) {
      return items.slice(
        (this.currentPage - 1) * this.limitPerPage,
        this.currentPage * this.limitPerPage,
      );
    },
    getInitials(string) {
      return string
        .split(' ')
        .splice(0, 2)
        .map((s) => s[0])
        .join('');
    },
    get(string, object) {
      return string.split('.').reduce((p, c) => p?.[c], object);
    },
    getFiltersValues() {
      const filters = Object.entries(this.filterables).reduce((acc, [key]) => {
        // Array of objects case
        const splittedKeys = key.split('.');

        if (splittedKeys.length > 1) {
          const arrayKey = splittedKeys[0];
          const valueKey = splittedKeys.at(-1);

          const deepArrayValues = this.items.flatMap((v) => v[arrayKey]).map((v) => v[valueKey]);

          // Empty value at the start for the empty <select>'s <option>
          acc[key] = [...new Set(deepArrayValues)].filter(Boolean).sort();
        } else {
          // Date
          if (key.includes('date')) {
            acc[key] = [
              ...new Set(this.items.map((row) => new Date(row[key]).getFullYear())),
            ].filter(Boolean);
            return acc;
          }

          // Default: String
          acc[key] = [...new Set(this.items.map((row) => row[key]))].filter(Boolean).sort();
        }

        return acc;
      }, {});

      this.filters = filters;
    },
  };
}
