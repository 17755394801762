/**
 * Add/Remove indeterminate state on a checkbox input
 * @param {HTMLInputElement} input
 */
function toggleIndeterminateState(input) {
  input.indeterminate = true;
  if (input.classList.contains('switch--is-indeterminate')) {
    input.indeterminate = false;
    input.classList.remove('switch--is-indeterminate');
  } else {
    input.indeterminate = true;
    input.classList.add('switch--is-indeterminate');
  }
}

document.querySelectorAll('form.switch-group')?.forEach((/** @type {HTMLFormElement} */ group) => {
  const switches = group.querySelectorAll('input.switch--indeterminate');
  let shouldListenGroupWeight = switches.length;

  switches.forEach((input) => {
    if (!input.checked) {
      toggleIndeterminateState(input);
    } else {
      shouldListenGroupWeight -= 1;
    }
  });

  // Form is dirty, skip this group
  if (shouldListenGroupWeight <= 1) {
    return;
  }

  group.addEventListener(
    'change',
    () => {
      group.querySelectorAll('input.switch--indeterminate').forEach((input) => {
        console.log(input.checked);
        if (!input.checked) {
          toggleIndeterminateState(input);
        }
      });
    },
    { once: true },
  );
});
