/**
 * Modal subscribe.
 * @returns {any}
 */
export default function ModalSubscribe() {
  return {
    state: {},
    params: {
      form: 'formModalSubscribe',
    },
    path: `${window.maddy.ajax_url}?action=latestNewsSubmit`,
    async onSubmitModalSubscribe(event) {
      event.preventDefault();

      try {
        await fetch(this.path, {
          method: 'POST',
          body: new FormData(this.$refs[this.params.form]),
        })
          .then((response) => response.json())
          .then((data) => {
            this.onFormSuccess(data.message);
          });
      } catch (error) {
        this.onFormFailure(error.message);
      }
    },
    onFormSuccess(message) {
      this.$refs[this.params.form].innerHTML = message;
    },
    onFormFailure(error) {
      this.$refs[this.params.form].innerHTML = error;
    },
    onClickModalSubscribe() {
      this.$refs[this.params.form].className += ' validate';
    },
    init() {
      // Silence is golden.
    },
    mounted() {
      this.init();
    },
  };
}
