import Swiper from 'swiper/bundle';

/**
 * /heroCarousel.js
 * @returns {object}
 */
export default function logoCarousel() {
  return {
    slider: null,
    next: null,
    prev: null,

    initSlider() {
      this.slider = new Swiper(this.$refs.slider, {
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 60,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
          768: {
            centerSlides: true,
            slidesPerView: 'auto',
          },
          992: {
            loop: true,
            centeredSlides: true,
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 0,
          },
        },
      });
    },

    mounted() {
      this.initSlider();
    },
  };
}
