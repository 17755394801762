import Swiper from 'swiper/bundle';

/**
 * Promo carousel.
 * @returns {object}
 */
export default function PromoPlayCarousel() {
  return {
    instance: null,
    progress: 0,
    init() {
      const options = {
        slidesPerView: 1,
        spaceBetween: 24,
        breakpoints: {
          576: {
            spaceBetween: 38,
          },
          768: {
            spaceBetween: 34,
          },
          992: {
            slidesPerView: 2,
          },
          1600: {
            slidesPerView: 3,
          },
        },
        navigation: {
          prevEl: this.$refs.prev || null,
          nextEl: this.$refs.next || null,
        },
        on: {
          init: (swiper) => {
            this.progress = Number.parseFloat(swiper.progress);
          },
          slideChange: (swiper) => {
            this.progress = Number.parseFloat(swiper.progress);
          },
          afterInit: (swiper) => {
            const { slides } = swiper;
            slides.forEach((slide) => {
              slide.addEventListener('mouseenter', (event) => this.slideOnMouseEnter(event, slide));
              slide.addEventListener('mouseleave', (event) => this.slideOnMouseLeave(event, slide));
            });
          },
        },
      };

      this.instance = new Swiper(this.$refs.carousel, options);
    },

    slideOnMouseEnter(event) {
      event.target.querySelector('video').play();
    },

    slideOnMouseLeave(event) {
      event.target.querySelector('video').pause();
    },

    progressBarStyle() {
      return {
        width: `${this.progress * 100}%`,
      };
    },
    mounted() {
      this.init();
    },
  };
}
