import Swiper from 'swiper/bundle';
import { breakpoints } from '../../utils/breakpoints.js';

/**
 * WalletTable.
 * @param {any} props
 * @returns {any}
 */
export default function WalletTable(props) {
  return {
    // Search index
    index: null,
    items: [],
    initialParams: `investor_id=${props.investor_id}`,
    paramsWithoutSort: `investor_id=${props.investor_id}`,
    sortedParams: '',
    params: `investor_id=${props.investor_id}`,
    // Data is used to display the table
    data: [],
    currentPage: 1,
    // A list of columns to show as the table header
    // The current sorted key
    sortKey: '',
    order: 'ASC',
    action: props?.action ? props.action : '',
    // Is the sort reversed or not
    isReverseSorted: false,
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`).matches,
    async init() {
      if (this.$refs.swiperCards) {
        this.cardsSlider = new Swiper(this.$refs.swiperCards, {
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        });
      }

      if (props?.apiUrl) {
        this.fetchPosts();
      }
    },
    fetchPosts() {
      fetch(props.apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `action=${this.action}&security=custom_nonce&${this.params}`,
      })
        .then((response) => response.json())
        .then((response) => {
          this.$refs.listing.innerHTML = response.listing;
          this.$refs.pagination.innerHTML = response.pagination;
        });
    },
    async mounted() {
      await this.init();
    },
    filterBy() {
      const formData = new FormData(this.$refs.form);
      const filters = Object.entries(Object.fromEntries(formData));
      this.params = this.initialParams;

      for (const entry of filters) {
        if (entry[1] !== '') {
          this.params += `&${entry[0]}=${entry[1]}`;

          if (entry[0] !== 'paged') {
            this.paramsWithoutSort += `&${entry[0]}=${entry[1]}`;
          }
        }
      }

      if (this.sortedParams !== '') {
        this.params += this.sortedParams;
      }
      this.fetchPosts();
    },
    sortBy(key) {
      this.params = this.paramsWithoutSort;
      if (this.sortKey === key && this.order === 'DESC') {
        this.resetSorting();
        return;
      }

      if (this.sortKey === key) {
        this.order = 'DESC';
        this.isReverseSorted = true;
      } else {
        this.order = 'ASC';
        this.isReverseSorted = false;
      }

      this.sortKey = key;
      this.sortedParams = `&orderby=${key}&order=${this.order}`;
      this.params += this.sortedParams;
      this.fetchPosts();
    },
    resetSorting() {
      this.sortKey = '';
      this.order = 'ASC';
      this.isReverseSorted = false;
      this.sortedParams = '';
      this.fetchPosts();
    },
    getInitials(string) {
      return string
        .split(' ')
        .splice(0, 2)
        .map((s) => s[0])
        .join('');
    },
  };
}
