import Plyr from 'plyr';
import gsap from 'gsap';

/**
 * YoutubeIframeVideo.
 * @returns {any}
 */
export default function YoutubeIframeVideo() {
  return {
    el: null,
    player: null,
    playButton: null,
    init() {
      // Silence is golden.
    },
    mounted() {
      const element = this.el.querySelector('#player');
      this.playButton = this.el.querySelector('.play-btn');
      this.player = new Plyr(element, {});

      this.el.addEventListener('mouseenter', this.mouseenter.bind(this));
      this.el.addEventListener('mouseleave', this.mouseleave.bind(this));
      this.el.addEventListener('mousemove', this.mousemove.bind(this));

      this.playButton.addEventListener('click', () => {
        if (this.player.paused) {
          this.player.play();
        } else {
          this.player.pause();
        }
      });
      this.init();
    },
    mousemove(event) {
      if (this.player.paused) {
        gsap.to(this.playButton, {
          duration: 0.3,
          x: event.x - 40,
          y: event.y - 40,
        });
      } else {
        gsap.to(this.playButton, {
          duration: 0.3,
          opacity: 0,
          scale: 0,
        });
      }
    },
    mouseenter() {
      gsap.to(this.playButton, {
        duration: 0.3,
        opacity: 1,
        scale: 1,
      });
    },
    mouseleave() {
      gsap.to(this.playButton, {
        duration: 0.3,
        opacity: 0,
        scale: 0,
      });
    },
  };
}
