/**
 * Chart bubble.
 * @param   {any} props
 * @returns {any}
 */
export default function chartBubble(props) {
  return {
    path: `${window.maddy.ajax_url}?action=getGraphData&type=`,
    nodes: props?.nodes || {},
    edges: props?.edges || {},
    repulsion: 150,
    async init() {
      await fetch(String(this.path) + props.type)
        .then((response) => response.json())
        .then((data) => {
          this.nodes = data.nodes || [];
          this.edges = data.edges || [];
          this.repulsion = data.repulsion || 150;
        });
      const { init } = await import('echarts');
      const chart = init(this.$refs.chart);
      const options = {
        series: [
          {
            type: 'graph',
            layout: 'force',
            data: this.nodes,
            edges: this.edges,
            roam: true,
            emphasis: false,
            label: {
              show: true,
              align: 'center',
              rich: {
                name: {
                  fontFamily: `'Agrandir', sans-serif`,
                  fontWeight: 700,
                },
                value: {
                  fontFamily: `'Agrandir', sans-serif`,
                  fontWeight: 400,
                },
              },
            },
            force: {
              repulsion: this.repulsion,
            },
          },
        ],
      };

      chart.setOption(options);
      window.addEventListener('resize', () => {
        chart.resize();
      });
    },
    async mounted() {
      await this.init();
    },
  };
}
