import { createApp } from 'petite-vue';
import { displayPopup } from './utils/display-popup.js';
import Menu from './modules/menu.js';
import Mode from './modules/mode.js';
import HeroCarousel from '../blocks/hero-carousel/heroCarousel.js';
import ShortListCarousel from '../blocks/in-short/shortListCarousel.js';
import PromoPlayCarousel from '../blocks/promo-play/promoPlayCarousel.js';
import Collapse from './modules/collapse.js';
import ToggleDouble from './modules/toggleDouble.js';
import Dropdown from '../blocks/dropdown/dropdown.js';
import SelectFilter from '../blocks/select/select.js';
import Modal from '../blocks/modal/modal.js';
import ReviewsSlider from '../blocks/reviews-slider/reviews-slider.js';
import ReviewsSliderSync from '../blocks/reviews-slider-sync/reviews-slider-sync.js';
import InvestorsSlider from './modules/investors/slider.js';
import SortableTable from './modules/investors/sortable-table.js';
import WalletTable from './modules/investors/wallet-table.js';
import LastFunds from './modules/investors/last-funds.js';
import WalletOverview, { WalletOverviewRanking } from './modules/investors/wallet-overview.js';
import ActiveNavigation from './modules/investors/active-navigation.js';
import ElasticListing from './modules/investors/elastic-listing.js';
import MaddymoneyCharts from './modules/maddymoney/charts.js';
import MaddymoneyLineChart from './modules/maddymoney/line-chart.js';
import MaddymoneyRanking from './modules/maddymoney/ranking.js';
import ServiceDetailsCarousel from '../blocks/services-carousel/serviceDetailsCarousel.js';
import Chart from './modules/chart.js';
import Layout from './modules/layout.js';
import PlayCarousel from './modules/playCarousel.js';
import $store from './modules/store.js';
import HeaderSearch from '../blocks/header-search/header-search.js';
import SocialsBar from '../blocks/socials-bar/socials-bar.js';
import Filters from '../blocks/filters/filters.js';
import InputSelect from '../blocks/input-select/input-select.js';
import ChartHistogram from '../blocks/chart-histogram/chart-histogram.js';
import ChartRegions from '../blocks/chart-regions/chart-regions.js';
import ChartBubble from '../blocks/chart-bubble/chart-bubble.js';
import Event from '../blocks/event/event.js';
import VideoProvided from '../blocks/video-provided/video-provided.js';
import VideoSelfHosted from '../blocks/video-self-hosted/video-self-hosted.js';
import ButtonCursorArea from './modules/buttonCursorArea.js';
import ArticlePodcast from './modules/articlePodcast.js';
import OutilLoader from './modules/outil-loader.js';
import LoadAgendaList from './modules/load-agenda-list.js';
import ContactForm from '../blocks/contacts-form/contact-form.js';
import LoadInShort from './modules/load-in-short.js';
import OngContainer from './modules/ong-container.js';
import EnterpriseLoader from './modules/enterprise-loader.js';
import IncubatorLoader from './modules/incubator-loader.js';
import ErrorForm from './modules/error-form.js';
import MaddyBasicList from './modules/maddy-basic-list.js';
import InputFile from './modules/inputFile.js';
import YoutubeIframeVideo from './modules/YoutubeIframeVideo.js';
import Readmore from './modules/Readmore.js';
import ModalSubscribe from '../blocks/modal-subscribe/modal-subscribe.js';
import externalLinksToNewTab from './utils/externalLinksToNewTab.js';
import StickyInvestor from './modules/investors/sticky-investor.js';
import StickyPremiumNav from './modules/investors/sticky-premium-nav.js';
import LogoCarousel from './modules/landing-maddymoney/logoCarousel.js';
import SliderVitrine from './modules/landing-maddymoney/SliderVitrine.js';
import EventLink from './modules/EventLink.js';
import './modules/click-counter.js';
import './modules/woocommerce.js';
import './modules/maddyplay-autoplay.js';
import './modules/investors/switch-group.js';
import './modules/poool.js';

externalLinksToNewTab();

createApp({
  $store,
  Layout,
  Menu,
  HeroCarousel,
  ShortListCarousel,
  PromoPlayCarousel,
  Mode,
  Dropdown,
  Modal,
  SelectFilter,
  ToggleDouble,
  ReviewsSlider,
  ReviewsSliderSync,
  ServiceDetailsCarousel,
  Chart,
  PlayCarousel,
  HeaderSearch,
  SocialsBar,
  Filters,
  InputSelect,
  ChartHistogram,
  ChartRegions,
  ChartBubble,
  Event,
  VideoProvided,
  VideoSelfHosted,
  ArticlePodcast,
  OutilLoader,
  LoadAgendaList,
  ContactForm,
  LoadInShort,
  OngContainer,
  EnterpriseLoader,
  IncubatorLoader,
  ErrorForm,
  MaddyBasicList,
  PartagerShare: () => ({ displayPopup }),
  ModalSubscribe,
  InputFile,
  InvestorsSlider,
  SortableTable,
  WalletTable,
  LastFunds,
  MaddymoneyCharts,
  ActiveNavigation,
  MaddymoneyRanking,
  WalletOverview,
  WalletOverviewRanking,
  MaddymoneyLineChart,
  ElasticListing,
  YoutubeIframeVideo,
  Readmore,
  StickyInvestor,
  StickyPremiumNav,
  LogoCarousel,
  SliderVitrine,
  EventLink,
})
  .directive('collapse', Collapse)
  .directive('button-cursor-area', ButtonCursorArea)
  .mount();

window.$store = $store;
