import $ from 'jquery';

const wooInstance = {
  is_cart() {
    // check is cart page
    if ($(document).find('.woocommerce-cart').length) {
      return true;
    }

    return false;
  },
  is_checkout() {
    // check is cart page
    if ($(document).find('.woocommerce-checkout').length) {
      return true;
    }

    return false;
  },
  mdn_update_cart_btn: $('.js-mdn-cart-refresh-button'),
  preloader_on(message) {
    let msg = 'Please wait..';
    if (typeof message !== 'undefined' && message) {
      msg = message;
    }
    const bodyEl = $('body');
    bodyEl.addClass('processing').block({
      message: msg,
      overlayCSS: {
        background: '#fff',
        opacity: 0.6,
      },
    });
    bodyEl.addClass('mdn-preloader');
  },
  preloader_off() {
    const bodyEl = $('body');
    bodyEl.removeClass('processing').unblock();
    bodyEl.removeClass('mdn-preloader');
  },
};
// Bind Custom refresh cart button
$(document).on('click', '.js-mdn-cart-refresh-button', () => {
  // if cart page
  if (wooInstance.is_cart()) {
    // set cart custom update cart button
    const updateCartButton = $('button[name=update_cart]');
    if (updateCartButton.prop('disabled')) {
      // Nothing updated in the cart.
      wooInstance.preloader_on('Rien de mis à jour dans le panier.');
      setTimeout(() => {
        wooInstance.preloader_off();
      }, 1500);
      return false;
    }

    updateCartButton.bind('click').trigger('click');
    return true;
  }

  return false;
});

$(document).on('click', '.js-single-product-add-to-cart', () => {
  $('button[name="add-to-cart"]').trigger('click');
});

// bind coupon input into cart form
$(document).on(
  'keypress focus blur copy paste keyup',
  '.js-mdn-cart-coupon-row input[name="coupon_code"]',
  () => {
    const couponFormInputEl = $('#coupon_code');
    couponFormInputEl.val($(this).val()).trigger('change');
  },
);
// bind coupon submit button into cart form
$(document).on('click', '.js-mdn-cart-coupon-row button[name="apply_coupon"]', () => {
  $('.js-mdn-cart-apply-coupon-btn').trigger('click');
});
$(document).on('applied_coupon', () => {
  $('.js-mdn-cart-coupon-row input[name="coupon_code"]').val('');
});
