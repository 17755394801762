/**
 * rafThrottle
 * @param {Function} fn
 * @returns {Function}
 */
export default function rafThrottle(fn) {
  let isRunning;
  let that;
  let args;

  const run = () => {
    isRunning = false;
    fn.apply(that, args);
  };

  return function raf() {
    that = this;

    args = arguments;

    if (isRunning) {
      return;
    }

    isRunning = true;
    requestAnimationFrame(run);
  };
}
