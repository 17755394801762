import Swiper from 'swiper/bundle';
import { breakpoints } from '../../js/utils/breakpoints.js';

/**
 * serviceDetailsCarousel
 * @returns {object}
 */
export default function serviceDetailsCarousel() {
  return {
    swiperInstance: null,
    allowSlideNext: false,
    allowSlidePrev: false,
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),
    initSlider() {
      const target = this.$refs.carousel || null;

      const navigation =
        this.$refs.prev && this.$refs.next
          ? {
              navigation: {
                prevEl: this.$refs.prev || null,
                nextEl: this.$refs.next || null,
              },
            }
          : undefined;

      const options = {
        slidesPerView: 2,
        spaceBetween: 34,
        grabCursor: true,
        ...navigation,
        breakpoints: {
          1200: {
            slidesPerView: 3,
          },
        },
      };

      this.swiperInstance = new Swiper(target, options);
    },
    destroySlider() {
      this.swiperInstance.destroy();
      this.swiperInstance = null;
    },
    mounted() {
      if (!this.matchMobile.matches) {
        this.initSlider();
      }

      this.matchMobile.addListener(() => {
        if (this.matchMobile.matches) {
          if (this.swiperInstance) {
            this.destroySlider();
          }
        } else if (!this.swiperInstance) {
          this.initSlider();
        }
      });
    },
  };
}
