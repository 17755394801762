/**
 * EventLink component.
 * @returns {any}
 */
export default function EventLink() {
  return {
    sendDatalayer(data) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    },
  };
}
