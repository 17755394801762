/**
 * Maddy basic list.
 * @param {any} props
 * @returns {any}
 */
export default function MaddyBasicList(props) {
  return {
    state: {
      items: props.items,
      query: {},
      nextPage: 2,
    },
    path: '?isAjaxRequest=1&',
    async changeFilter(event = null) {
      if (event?.detail?.type) {
        this.state.query[event.detail.type] = event.detail.newValue;
      }
      const query = Object.keys(this.state.query)
        .reduce((e, t) => {
          const s = this.state.query[t];
          return (
            s &&
              ((typeof s === 'string' && s.length) || typeof s === 'number') &&
              e.push(`${t}=${s}`),
            e
          );
        }, [])
        .join('&');
      await this.getEnterpriseItems(query);
    },
    async getEnterpriseItems(query) {
      try {
        await fetch(String(this.path) + query)
          .then((response) => response.json())
          .then((data) => {
            this.state.items = data.posts;
            if (data.nextPage) {
              this.state.nextPage = data.nextPage;
            }
          });
      } catch {
        // console.log(error);
      }
    },
    init() {
      document
        .querySelector('.filters')
        .addEventListener('filters:change', (event) => this.changeFilter(event));
    },
    mounted() {
      this.init();
    },
  };
}
