/**
 * Error form.
 * @returns {any}
 */
export default function ErrorForm() {
  return {
    state: {},
    path: `${window.maddy.ajax_url}?action=popupErrorSubmit`,
    params: {
      success: 'successErrorMessage',
      failure: 'failureErrorMessage',
      form: 'formError',
    },
    async sendFormError(event) {
      event.preventDefault();
      try {
        await fetch(this.path, {
          method: 'POST',
          body: new FormData(this.$refs[this.params.form]),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              this.onFormSuccess(data);
            } else {
              this.onFormFailure(data);
            }
          });
      } catch (error) {
        this.onFormFailure(error);
      }
    },
    onClickSendFormError() {
      this.$refs[this.params.form].className += ' validate';
    },
    onFormSuccess(e) {
      this.hideErrorForm();
      const element = this.$refs[this.params.success];
      element.style.display = 'block';
      element.querySelector('.message').innerHTML = e.message;
    },
    onFormFailure(e) {
      this.hideErrorForm();
      const element = this.$refs[this.params.failure];
      element.style.display = 'block';
      element.querySelector('.message').innerHTML = e.message;
    },
    hideErrorForm() {
      this.$refs[this.params.form].style.display = 'none';
    },
    async init() {
      // silence is golden.
    },
    async mounted() {
      await this.init();
    },
  };
}
