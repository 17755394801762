/**
 * MaddymoneyRanking.
 * @param {any} props
 * @returns {object}
 */
export default function MaddymoneyRanking(props) {
  return {
    type: props.type, //  regions | sectors,
    period: props?.period ? props.period : 'half-yearly',
    data: [],
    index: 0,
    slideIndex: 0,
    totalSlides: 0,
    loading: true,
    noRequest: props?.noRequest ? props.noRequest : false,
    async updateData() {
      this.loading = true;
      fetch(props.apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `action=load_wallet_data&security=custom_nonce&type=${this.type}&date=${this.period}`,
      })
        .then((response) => response.json())
        .then((response) => {
          this.$refs.listing.innerHTML = response.listing;
          this.$refs.walletSlider = this.$refs.listing.querySelector('[ref="walletSlider"]');
          this.slideIndex = 0;
          this.totalSlides = this.$refs.listing.querySelector('[data-total]').dataset.total;
          this.loading = false;
        });
    },
    prevSlide() {
      if (this.slideIndex === 0) {
        return;
      }
      this.slideIndex -= 1;
      const percent = this.slideIndex * -100;
      this.$refs.walletSlider.style.transform = `translateX(${percent}%)`;
    },
    nextSlide() {
      if (this.slideIndex === this.totalSlides - 1) {
        return;
      }
      this.slideIndex += 1;
      const percent = this.slideIndex * -100;
      this.$refs.walletSlider.style.transform = `translateX(${percent}%)`;
    },
    handlePeriodChange() {
      this.resizeSelect();
      this.updateData();
    },
    resizeSelect() {
      if (this.$refs.period === undefined) {
        return;
      }
      const selectedOption = this.$refs.period.querySelector('option:checked');
      const text = selectedOption ? selectedOption.textContent : '';

      const testSpan = document.createElement('span');
      testSpan.innerHTML = text;
      testSpan.style.visibility = 'hidden';

      this.$refs.period.parentNode.append(testSpan);
      const width = testSpan.offsetWidth + 20;
      testSpan.remove();

      this.$refs.period.style.width = `${width}px`;
    },
    mounted() {
      this.resizeSelect();
      if (!this.noRequest) {
        this.updateData();
      }
    },
  };
}
