import { breakpoints } from '../../utils/breakpoints.js';

/**
 * /sticky-premium-nav.js
 * @returns {object}
 */
export default function StickyPremiumNav() {
  return {
    el: null,
    heroTop: 0,
    isSticky: false,
    isOpen: false,
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),
    mounted() {
      this.heroTop = document.querySelector('.investors__cover').offsetTop;
      this.asideTop = document.querySelector('.investors__aside-inner').offsetTop;
      this.socialWidth = document.querySelector('.investors__links-right').offsetWidth;

      window.addEventListener('click', (e) => {
        if (!this.$refs.btn?.contains(e.target)) {
          this.hideNav();
        }
      });

      if (!this.matchMobile.matches) {
        window.addEventListener('scroll', () => {
          if (window.scrollY > this.asideTop && !this.isOpen) {
            this.isOpen = true;
            this.$refs.nav.style.transform = `translateX(-${this.socialWidth}px)`;
          }

          if (window.scrollY < this.asideTop && this.isOpen) {
            this.isOpen = false;
            this.$refs.nav.style.transform = `translateX(0)`;
          }
        });
      }

      if (this.matchMobile.matches) {
        window.addEventListener('scroll', () => {
          if (window.scrollY > this.heroTop && !this.isSticky) {
            this.isSticky = true;
            this.el.classList.add('is-sticky');
          }

          if (window.scrollY < this.heroTop && this.isSticky) {
            this.isSticky = false;
            this.el.classList.remove('is-sticky');
          }
        });
      }
    },
    showNav() {
      this.$refs.panel?.classList.add('visible');
    },
    hideNav() {
      this.$refs.panel?.classList.remove('visible');
    },
  };
}
