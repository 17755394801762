/**
 * Script loader
 * @param {string} src Source
 * @param {Function} callback On load callback function
 */
export default (src, callback) => {
  const script = document.createElement('script');
  script.addEventListener('load', callback);
  script.async = true;
  script.src = src;
  document.head.append(script);
};
