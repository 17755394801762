/**
 * chart-regions.js
 * @param {*} props
 * @returns {object}
 */
export default function chartRegions(props) {
  return {
    series: props.series,
    optionsList: props.optionsList,
    data: props.data,
    state: {
      selectedRegionId: props.selectedRegionId,
    },
    init() {
      this.$refs.regionSelect.addEventListener('change', ({ detail }) => {
        this.state.selectedRegionId = detail.value;
      });
    },
    mounted() {
      this.init();
    },
  };
}
