import gsap from 'gsap';
import rafThrottle from '../utils/rafThrottle.js';

/**
 * buttonCursorArea.js
 * @param {*} ctx
 */
export default function buttonCursorArea(ctx) {
  const area = ctx.el;
  const button = area.querySelector('.btn-cursor');
  const buttonWidth = button.offsetWidth;
  const buttonHeight = button.offsetHeight;
  const mediaHasMouse = window.matchMedia(
    '(hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0)',
  );

  // utils - start
  const setStyles = () => {
    button.style.cssText = `
      visibility: hidden;
      position: fixed;
      left: 0;
      top: 0;
      pointer-events: none;
      opacity: 0;
    `;
    area.style.cursor = 'pointer';
  };

  const resetStyles = () => {
    button.style.cssText = '';
    area.style.cssText = '';
  };

  const followCursor = (event) => {
    gsap.to(button, {
      duration: 0.1,
      x: event.x - buttonWidth / 2,
      y: event.y - buttonHeight / 2,
    });
  };

  const followCursorThrottled = rafThrottle(followCursor);

  const initCursorButton = () => {
    area.addEventListener('mousemove', followCursorThrottled);
    button.style.transition = 'opacity .2s ease';
    button.style.visibility = 'visible';
    button.style.opacity = '1';
  };

  const destroyCursorButton = () => {
    gsap.killTweensOf(button);
    button.style.transition = 'opacity .2s ease, visibility 0s ease .2s';
    button.style.visibility = 'hidden';
    button.style.opacity = '0';
    area.removeEventListener('mousemove', followCursorThrottled);
  };

  const init = () => {
    setStyles();
    area.addEventListener('mouseenter', initCursorButton);
    area.addEventListener('mouseleave', destroyCursorButton);
  };

  const destroy = () => {
    resetStyles();
    area.removeEventListener('mouseenter', initCursorButton);
    area.removeEventListener('mouseleave', destroyCursorButton);
  };

  // utils - end

  // script - start

  if (mediaHasMouse.matches) {
    init();
  }

  mediaHasMouse.addListener(() => {
    if (mediaHasMouse.matches) {
      init();
    } else {
      destroy();
    }
  });

  // script - end
}
