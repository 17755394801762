/**
 * Load agenda list.
 * @returns {any}
 */
export default function LoadAgendaList() {
  return {
    init() {
      // Silence is golden.
    },
    nextItem(event) {
      const button = event.currentTarget;
      const accordion = button.nextElementSibling;

      button.classList.add('d-none');
      accordion.classList.remove('d-none');
    },
    mounted() {
      this.init();
    },
  };
}
