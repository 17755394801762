/**
 * meun
 * @returns {object}
 */
export default function menu() {
  return {
    classNames() {
      return {
        'is-show': this.$store.isOpenMenu,
      };
    },
  };
}
