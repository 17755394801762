/**
 * mode
 * @returns {object}
 */
export default function mode() {
  return {
    checked: false,
    toggleMode(e) {
      this.$store.isDarkMode = e.target.checked;
      this.checked = this.$store.isDarkMode;
      localStorage.setItem('dark-mode', this.$store.isDarkMode);
    },
    mounted() {
      if (localStorage.getItem('dark-mode')) {
        this.$store.isDarkMode = JSON.parse(localStorage.getItem('dark-mode'));
        this.checked = this.$store.isDarkMode;
      }
    },
  };
}
