/**
 * createElementFromHTML
 * @param {string} htmlString
 * @returns {HTMLElement}
 */
export default function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstElementChild;
}
