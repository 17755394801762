/**
 * LastFunds.
 * @param {any} props
 * @returns {any}
 */
export default function LastFunds(props) {
  return {
    initialParams: '',
    paramsWithoutSort: '',
    sortedParams: '',
    params: '',
    order: 'ASC',
    loadmoreClicked: false,
    action: props?.action ? props.action : '',
    async init() {
      if (props?.apiUrl) {
        this.fetchPosts();
      }
    },
    fetchPosts() {
      fetch(props.apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `action=${this.action}&security=custom_nonce&${this.params}`,
      })
        .then((response) => response.json())
        .then((response) => {
          // Mobile condition
          if (this.loadmoreClicked) {
            this.loadmoreBtn.remove();
            this.$refs.listing.insertAdjacentHTML('beforeend', response.listing);
          } else {
            this.$refs.listing.innerHTML = response.listing;
            this.$refs.pagination.innerHTML = response.pagination;
          }

          this.loadmoreBtn = document.querySelector('.fund-item--loadmore');
          this.loadmoreBtn.addEventListener('click', () => {
            this.loadMore();
          });
        });
    },
    filterBy() {
      const formData = new FormData(this.$refs.form);
      const filters = Object.entries(Object.fromEntries(formData));
      this.params = this.initialParams;

      for (const entry of filters) {
        if (entry[1] !== '') {
          this.params += `&${entry[0]}=${entry[1]}`;

          if (entry[0] !== 'paged') {
            this.paramsWithoutSort += `&${entry[0]}=${entry[1]}`;
          }
        }
      }

      if (this.sortedParams !== '') {
        this.params += this.sortedParams;
      }
      this.fetchPosts();
    },
    loadMore() {
      const { paged } = this.loadmoreBtn.dataset;
      this.loadmoreClicked = true;
      this.params += `&paged=${paged}`;
      this.fetchPosts();
    },
    async mounted() {
      await this.init();
    },
  };
}
