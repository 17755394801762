import Swiper from 'swiper/bundle';
import { breakpoints } from '../../js/utils/breakpoints.js';

/**
 * /heroCarousel.js
 * @returns {object}
 */
export default function heroCarousel() {
  return {
    sliderImages: null,
    sliderText: null,
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),

    initMobileSliders() {
      this.sliderText = new Swiper(this.$refs.sliderText);

      this.sliderImages = new Swiper(this.$refs.sliderImages, {
        effect: 'fade',
        speed: 1000,
        fadeEffect: {
          crossFade: true,
        },
      });

      this.sliderImages.controller.control = this.sliderText;
      this.sliderText.controller.control = this.sliderImages;
    },

    initDesktopSliders() {
      const changeActiveSlide = (index) => {
        this.sliderImages.slideTo(index);
      };

      this.sliderText = new Swiper(this.$refs.sliderText, {
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        touchReleaseOnEdges: true,
        speed: 1000,
        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        on: {
          afterInit(swiper) {
            const { slides } = swiper;
            slides.forEach((slide, index) => {
              slide.addEventListener('mouseenter', () => changeActiveSlide(index));
            });
          },
        },
      });

      this.sliderImages = new Swiper(this.$refs.sliderImages, {
        effect: 'fade',
        speed: 1000,
        fadeEffect: {
          crossFade: true,
        },
        thumbs: {
          swiper: this.sliderText,
        },
      });
    },
    destroySliders() {
      this.sliderText.destroy();
      this.sliderImages.destroy();
      this.sliderText = null;
      this.sliderImages = null;
    },
    mounted() {
      if (this.matchMobile.matches) {
        this.initMobileSliders();
      } else {
        this.initDesktopSliders();
      }

      this.matchMobile.addListener(() => {
        this.destroySliders();
        if (this.matchMobile.matches) {
          this.initMobileSliders();
        } else {
          this.initDesktopSliders();
        }
      });
    },
  };
}
