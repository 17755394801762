import { breakpoints } from '../../js/utils/breakpoints.js';
import htmlFromString from '../../js/utils/htmlFromString.js';

/**
 * event.js
 * @returns {object}
 */
export default function event() {
  return {
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),
    columns: htmlFromString(`
      <div class="event__columns">
        <div class="event__column-left">
        </div>
        <div class="event__column-right">
        </div>
      </div>
    `),
    addWrappers() {
      const colLeft = this.columns.querySelector('.event__column-left');
      colLeft.append(this.$refs.register);
      colLeft.append(this.$refs.info);
      const colRight = this.columns.querySelector('.event__column-right');
      colRight.append(this.$refs.content);
      colRight.append(this.$refs.similar);

      this.$refs.inner.append(this.columns);
    },
    removeWrappers() {
      this.$refs.inner.append(this.$refs.register);
      this.$refs.inner.append(this.$refs.content);
      this.$refs.inner.append(this.$refs.info);
      this.$refs.inner.append(this.$refs.similar);
      this.columns.remove();
    },
    mounted() {
      if (!this.matchMobile.matches) {
        this.addWrappers();
      }

      this.matchMobile.addListener(() => {
        if (this.matchMobile.matches) {
          this.removeWrappers();
        } else {
          this.addWrappers();
        }
      });
    },
  };
}
