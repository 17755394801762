import Plyr from 'plyr';
import { breakpoints } from '../../js/utils/breakpoints.js';

/**
 * video-provided.js
 * @param {*} param0
 * @returns {object}
 */
export default function videoProvided({ provider, id }) {
  const matchMobile = window.matchMedia(`(max-width: ${breakpoints['$bp-sm']})`);

  return {
    el: null,
    player: null,
    ratio: null,

    state: {
      provider,
      id,
      previewMobile: null,
      previewDesktop: null,
    },
    initPlayer() {
      const player = this.el.querySelector('.video-provided__player');
      this.player = new Plyr(player, {
        ratio: this.ratio,
      });
      this.player.on('ready', () => {
        this.el.classList.add('is-loaded');
        this.player.play();
      });
    },
    destroyPlayer() {
      this.el.classList.remove('is-loaded');
      this.player.destroy();
      this.player = null;
    },
    getPreviewImages() {
      if (this.state.provider === 'vimeo') {
        this.getVimeoPreviewImages();
      }
      if (this.state.provider === 'youtube') {
        this.getYoutubePreviewImages();
      }
    },
    getVimeoPreviewImages() {
      fetch(`http://vimeo.com/api/v2/video/${this.state.id}.json`)
        .then((response) => response.json())
        .then((json) => {
          this.state.previewMobile = json[0].thumbnail_medium;
          this.state.previewDesktop = json[0].thumbnail_large;
        });
    },
    getYoutubePreviewImages() {
      this.state.previewMobile = `https://img.youtube.com/vi/${this.state.id}/hqdefault.jpg`;
      this.state.previewDesktop = `https://img.youtube.com/vi/${this.state.id}/maxresdefault.jpg`;
    },
    mounted() {
      this.getPreviewImages();
      this.ratio = matchMobile.matches ? '25:17' : '25:12';
      matchMobile.addListener(() => {
        if (this.player) {
          this.destroyPlayer();
        }
        this.ratio = matchMobile.matches ? '25:17' : '25:12';
      });
    },
  };
}
