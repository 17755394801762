import Swiper from 'swiper/bundle';
import { breakpoints } from '../../utils/breakpoints.js';

/**
 * /heroCarousel.js
 * @returns {object}
 */
export default function SliderVitrine() {
  return {
    slider: null,
    next: null,
    prev: null,
    slidesMobile: '',
    slidesDesktop: '',
    matchMobile: window.matchMedia(`(max-width: ${breakpoints['$bp-md']})`),

    initSlider() {
      this.slider = new Swiper(this.$refs.slider, {
        autoplay: {
          delay: 5000,
        },
        centeredSlides: true,
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        slidesPerView: 1,
        loop: true,
      });

      if (this.matchMobile.matches) {
        this.slider.addSlide(1, this.$refs.slidesMobile.innerHTML);
      } else {
        this.slider.addSlide(1, this.$refs.slidesDesktop.innerHTML);
      }
    },

    mounted() {
      this.initSlider();
    },
  };
}
