import Swiper from 'swiper/bundle';

/**
 * ReviewSliderSync.
 * @returns {any}
 */
export default function reviewsSlider() {
  return {
    sliderText: null,
    sliderLogo: null,
    init() {
      // const sliderTextSlides = this.$refs.sliderText.querySelectorAll('.swiper-slide');
      const sliderLogoSlides = this.$refs.sliderLogo.querySelectorAll('.swiper-slide');

      this.sliderText = new Swiper(this.$refs.sliderText, {
        watchOverflow: true,
        autoHeight: true,
        navigation: {
          nextEl: this.$refs.btnNextText,
          prevEl: this.$refs.btnPrevText,
        },
        slidesPerView: 1,
        spaceBetween: 40,
      });

      this.sliderLogo = new Swiper(this.$refs.sliderLogo, {
        spaceBetween: 40,
        centeredSlides: true,
        watchOverflow: true,
        allowTouchMove: false,
        breakpoints: {
          768: {
            slidesPerView:
              sliderLogoSlides.length < 5 ? sliderLogoSlides.length + 2 : sliderLogoSlides.length,
            spaceBetween: 6,
          },
        },
      });

      this.sliderText.controller.control = this.sliderLogo;
    },
    mounted() {
      this.init();
    },
  };
}
