import { setPaddingBody, removePaddingBody } from '../../js/utils/scrollbar.js';

/**
 * Filters.
 * @param   {any} props
 * @returns {any}
 */
export default function filters(props) {
  return {
    el: null,
    state: {
      items: props.items,
    },
    init() {
      this.el.addEventListener('select-filter:change', ({ detail }) => {
        this.updateSelected(detail.name, detail.id, '', detail.type);
      });
    },
    mounted() {
      this.init();
    },
    updateSelected(filterName, newValue, query = '', type = '') {
      if (
        ((this.state.items.find((e) => e.name === filterName).selected = newValue),
        this.triggerCustomChangeEvent({
          filterName,
          newValue,
          query,
          type,
        }),
        query && query.length)
      ) {
        const url = new URL(window.location.href);
        const data = url.search.length
          ? (url.search.replace('?', '').split('&') || []).reduce((e, t) => {
              const tSplit = t.split('=');

              e[tSplit[0]] = tSplit[1];
              return e;
            }, {})
          : {};
        data[query] = newValue;
        const params = Object.entries(data)
          .reduce((e, [t, s]) => {
            e.push(`${t}=${s}`);
            return e;
          }, [])
          .join('&');
        window.location.href = `${url.origin + url.pathname}?${params}`;
      }
    },
    openMobile() {
      document.body.append(this.$refs.filtersMobile);
      setPaddingBody();
    },
    closeMobile() {
      this.$refs.filtersMobileWrapper.append(this.$refs.filtersMobile);
      removePaddingBody();
    },
    triggerCustomChangeEvent({ filterName, newValue, query, type }) {
      const event = new CustomEvent('filters:change', {
        detail: {
          filterName,
          newValue,
          query,
          type,
        },
      });
      this.el.dispatchEvent(event);
    },
  };
}
