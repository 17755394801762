import gsap from 'gsap';

/**
 * Layout.
 * @returns {any}
 */
export default function Layout() {
  return {
    get classList() {
      return {
        'is-dark-theme': this.$store.isDarkMode,
      };
    },
    cursorListener() {
      const createCursor = () => {
        const cursor = document.createElement('div');
        cursor.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 80px;
          height: 80px;
          border-radius: 40px;
          background-color: #BBCAFF;
          transition: translate 0.2s;
          scale(0);
          pointer-events: none;
          mix-blend-mode: multiply;
          opacity: 0;
          z-index: 1;
        `;

        return cursor;
      };

      const createPlayCursor = () => {
        const cursor = document.createElement('div');
        cursor.style.cssText = `
            position: fixed;
            transition: translate 0.2s;
            scale(0);
            pointer-events: none;
            opacity: 0;
            top: 0;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 83px;
            height: 83px;
            font-family: "Agrandir Wide",sans-serif;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: .01em;
            text-transform: uppercase;
            color: #fff;
            background-color: #3e68ff;
            transform: scale(0.8);
            cursor: pointer;
        `;
        cursor.textContent = 'Play';
        return cursor;
      };

      const cursor = createCursor();
      const playCursor = createPlayCursor();
      document.body.append(cursor);

      let isNewTarget = true;
      let isNewTargetPlay = true;

      document.addEventListener('mousemove', (event) => {
        if (event.target && event.target.closest('[data-cursor]')) {
          if (isNewTarget) {
            isNewTarget = false;
            gsap.to(cursor, {
              duration: 0,
              x: event.x - 40,
              y: event.y - 40,
            });
          } else {
            gsap.to(cursor, {
              duration: 0.3,
              x: event.x - 40,
              y: event.y - 40,
            });
          }

          gsap.to(cursor, {
            duration: 0.3,
            opacity: 1,
            scale: 1,
          });
        } else if (!isNewTarget) {
          isNewTarget = true;
          gsap.killTweensOf(cursor);
          gsap.to(cursor, {
            delay: 0.1,
            duration: 0.2,
            scale: 0,
            opacity: 0,
          });
        }

        if (event.target && event.target.closest('[data-cursor-play]')) {
          if (isNewTargetPlay) {
            isNewTargetPlay = false;
            gsap.to(playCursor, {
              duration: 0,
              x: event.x - 40,
              y: event.y - 40,
            });
          } else {
            gsap.to(playCursor, {
              duration: 0.3,
              x: event.x - 40,
              y: event.y - 40,
            });
          }

          gsap.to(playCursor, {
            duration: 0.3,
            opacity: 1,
            scale: 1,
          });
        } else if (!isNewTargetPlay) {
          isNewTargetPlay = true;
          gsap.killTweensOf(playCursor);
          gsap.to(playCursor, {
            delay: 0.1,
            duration: 0.2,
            scale: 0,
            opacity: 0,
          });
        }
      });
    },
    scrollListener() {
      let headerIsMinimized = false;
      const header = document.querySelector('.js-header');

      const changeLogoSize = () => {
        const topOffset = window.pageYOffset || document.documentElement.scrollTop;

        if (topOffset > 0 && !headerIsMinimized) {
          headerIsMinimized = true;
          header.classList.add('is-minimized');
        } else if (topOffset === 0 && headerIsMinimized) {
          headerIsMinimized = false;
          header.classList.remove('is-minimized');
        }
      };

      document.addEventListener('scroll', () => changeLogoSize());
      changeLogoSize();
    },
    // note: Currently disabled (https://studiometa.atlassian.net/browse/DESK-7877).
    showLeaveModal() {
      if (!localStorage.getItem('leaveModalShown')) {
        this.$store.modal('show', '#modal-on-leave');
        localStorage.setItem('leaveModalShown', 'true');
      }
    },
    mounted() {
      document.addEventListener('DOMContentLoaded', () => {
        this.cursorListener();
        this.scrollListener();
      });
    },
  };
}
