import HandyCollapse from 'handy-collapse';

/**
 * collapse.j
 * @param {any} ctx
 */
export default function collapse(ctx) {
  const options = {
    nameSpace: 'collapse',
    animationSpeed: 200,
    cssEasing: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
    closeOthers: false,
  };
  const element = ctx.el;
  const container = document.querySelector(`#${ctx.exp}`);

  element.dataset.collapseControl = ctx.exp;
  container.dataset.collapseContent = ctx.exp;

  new HandyCollapse(options);
}
