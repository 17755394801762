/**
 * ActiveNavigation.
 * @returns {any}
 */
export default function ActiveNavigation() {
  return {
    updateNavigation(entries) {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute('id');

        if (!id) return;

        if (entry.isIntersecting && entry.intersectionRatio >= 0.1) {
          document.querySelector('nav .active')?.classList.remove('active');
          document.querySelector(`[href="#${id}"]`)?.parentElement.classList.add('active');
        }
      });
    },
    mounted() {
      const observer = new IntersectionObserver(this.updateNavigation, {
        threshold: 0.2,
      });

      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        observer.observe(section);
      });
    },
  };
}
