import Plyr from 'plyr';
import { breakpoints } from '../../js/utils/breakpoints.js';

/**
 * video-self-hosted.js
 * @returns {object}
 */
export default function videoSelfHosted() {
  const matchMobile = window.matchMedia(`(max-width: ${breakpoints['$bp-sm']})`);

  return {
    el: null,
    player: null,
    ratio: null,
    initPlayer() {
      const player = this.el.querySelector('.video-self-hosted__player');
      this.player = new Plyr(player, {
        ratio: this.ratio,
      });
      this.player.on('ready', () => {
        this.el.classList.add('is-loaded');
        this.player.play();
      });
    },
    destroyPlayer() {
      this.el.classList.remove('is-loaded');
      this.player.destroy();
      this.player = null;
    },
    mounted() {
      this.ratio = matchMobile.matches ? '25:17' : '25:12';
      matchMobile.addListener(() => {
        if (this.player) {
          this.destroyPlayer();
        }
        this.ratio = matchMobile.matches ? '25:17' : '25:12';
      });
    },
  };
}
