/**
 * chart-histogram.js
 * @param {*} props
 * @returns {object}
 */
export default function chartHistogram(props) {
  return {
    path: `${window.maddy.ajax_url}?action=getGraphData&type=`,
    series: props?.series || {},
    data: props?.data || {},
    async init() {
      const url = props.region
        ? `${String(this.path) + props.type}&region=${props.region}`
        : String(this.path) + props.type;
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.series = data.series || {};
          this.data = data.data || {};
        });
      const { init } = await import('echarts');
      const chart = init(this.$refs.chart);

      chart.setOption({
        tooltip: {},
        legend: {
          bottom: 0,
          textStyle: {
            color: document.body.classList.contains('is-dark-theme') ? '#FFF' : '#666',
          },
        },
        xAxis: {
          data: this.data,
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },
        yAxis: {},
        series: this.series,
        grid: {
          bottom: 80,
          right: 0,
        },
      });

      window.addEventListener('resize', () => {
        chart.resize();
      });
    },
    mounted() {
      this.init();
    },
  };
}
