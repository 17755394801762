/**
 * Ong Container.
 * @returns {any}
 */
export default function OngContainer() {
  return {
    el: null,
    query: null,
    init() {
      this.query = {
        action: 'getOngContent',
        type: this.el.dataset.type ?? 0,
        article_id: this.el.dataset.articleId ?? 0,
        category_id: this.el.dataset.categoryId ?? 0,
        format: this.el.dataset.format ?? 0,
        format_mobile: this.el.dataset.formatMobile ?? 0,
        publicite_id: this.el.dataset.publiciteId ?? 0,
        only_desktop: Number(!!Object.hasOwn(this.el.dataset, 'onlyDesktop')),
      };

      this.loadOngContent();
    },
    loadOngContent() {
      const url = `${window.maddy.ajax_url}?${this.serialize(this.query)}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.advertising) {
            this.el.classList.remove('d-none');
            this.el.innerHTML = data.advertising;
          }
        })
        .catch(() => {
          this.el.innerHTML = 'Une erreur est survenue.';
        });
    },
    /**
     * @todo Use URLSearchParams instead.
     * @param   {any} obj
     * @returns {string}
     */
    serialize(obj) {
      const params = [];

      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }

      return params.join('&');
    },
    mounted() {
      this.init();
    },
  };
}
