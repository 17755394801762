/**
 * When Maddyplay videos enters the viewport start the autoplay
 * @param {HTMLVideoElement[]} videos
 */
function startAutoplayMaddyplay(videos) {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.setAttribute('autoplay', true);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: '0px',
      threshold: 1.0,
    },
  );

  videos.forEach((video) => {
    observer.observe(video);
  });
}

const maddyVideos = document.querySelectorAll('.promo-play-item-video');

if (maddyVideos.length > 0) {
  startAutoplayMaddyplay(maddyVideos);
}
