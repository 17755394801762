import Swiper from 'swiper/bundle';

/**
 * InvestorsSlider.
 * @param {any} props
 * @returns {any}
 */
export default function investorsSlider(props) {
  const DEFAULT_BREAKPOINTS = {
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  };

  return {
    breakpoints: props.breakpoints,
    next: null,
    previous: null,
    pagination: null,
    init() {
      const paginationConfig = this.$refs.pagination
        ? {
            el: this.$refs.pagination,
            clickable: true,
            renderBullet(index, className) {
              return `<button class="${className}">${index + 1}</button>`;
            },
          }
        : false;

      const navigationConfig = this.$refs.pagination
        ? {
            nextEl: this.$refs.next,
            prevEl: this.$refs.previous,
          }
        : false;

      this.slider = new Swiper(this.$refs.swiper, {
        slidesPerView: 1.2,
        spaceBetween: 6,
        pagination: paginationConfig,
        navigation: navigationConfig,
        breakpoints: this.breakpoints || DEFAULT_BREAKPOINTS,
      });
    },
    mounted() {
      this.init();
    },
  };
}
