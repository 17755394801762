import tippy from 'tippy.js';

/**
 * dropdown.js
 * @param {*} props
 * @returns {object}
 */
export default function Dropdown(props) {
  return {
    $template: props?.template,
    init() {
      const { trigger } = this.$refs;
      const dropdown = this.$refs.menu;

      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     const { width } = trigger.getBoundingClientRect()
      //     width > 200 && dropdown.style.setProperty('width', `${width}px`)
      //   }, 100)
      // })

      tippy(trigger, {
        content: dropdown,
        placement: 'bottom-start',
        trigger: 'click',
        offset: 0,
        interactive: true,
        zIndex: 49,
        popperOptions: {
          strategy: 'fixed',
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: false,
                tether: false,
              },
            },
          ],
        },
      });
    },
    mounted() {
      this.init();
    },
  };
}
