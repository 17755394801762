/**
 * chart.js
 * @param {*} props
 * @returns {object}
 */
export default function charts(props) {
  return {
    series: props?.series || {},
    data: props?.data || {},
    async init() {
      const { init } = await import('echarts');
      const chart = init(this.$refs.chart);
      const options = {
        tooltip: {},
        legend: {
          bottom: 0,
        },
        xAxis: {
          data: this.data,
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },
        yAxis: {},
        series: this.series,
        grid: {
          bottom: 80,
        },
      };

      chart.setOption(options);
      window.addEventListener('resize', () => {
        chart.resize();
      });
    },
    mounted() {
      this.init();
    },
  };
}
