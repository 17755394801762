import Swiper from 'swiper/bundle';

/**
 * ReviewSlider.
 * @returns {any}
 */
export default function reviewsSlider() {
  return {
    sliderText: null,
    sliderLogo: null,
    init() {
      this.sliderText = new Swiper(this.$refs.sliderText, {
        loop: this.$refs.sliderText.querySelectorAll('.swiper-slide').length > 1,
        watchOverflow: true,
        autoHeight: true,
        navigation: {
          nextEl: this.$refs.btnNextText,
          prevEl: this.$refs.btnPrevText,
        },
        slidesPerView: 1,
        spaceBetween: 40,
      });
      this.sliderLogo = new Swiper(this.$refs.sliderLogo, {
        loop: this.$refs.sliderLogo.querySelectorAll('.swiper-slide').length > 1,
        slidesPerView: 1,
        spaceBetween: 40,
        centeredSlides: true,
        watchOverflow: true,
        allowTouchMove: false,
        navigation: {
          nextEl: this.$refs.btnNextLogo,
          prevEl: this.$refs.btnPrevLogo,
        },
        breakpoints: {
          768: {
            slidesPerView: 5,
            spaceBetween: 6,
          },
        },
      });
    },
    mounted() {
      this.init();
    },
  };
}
