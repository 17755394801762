import { Base, createApp } from '@studiometa/js-toolkit';
import { Accordion } from '@studiometa/ui';

const resettableQueryParameters = [
  'query',
  'is_premium',
  'tax_investor_type',
  'tax_investor_aum',
  'tax_investor_series',
  'tax_investor_target_tickets',
  'tax_investor_support_services',
  'tax_funds_geographic_zones',
];

/**
 * ElasticListing.
 * @param {any} props
 * @returns {any}
 */
export default function ElasticListing(props) {
  return {
    view: props?.view ? props.view : 'cards', // 'table' | 'cards'
    count: props.count,
    i18n: {
      plural: props.i18n.plural,
      singular: props.i18n.singular,
    },
    pagination: null,
    items: [],
    params: '',
    hasFilters: false,
    appInstance: null,
    loading: true,
    sortKey: '',
    order: 'DESC',
    isReverseSorted: false,
    currentInputNode: null,
    currentInputChange: '',
    currentPage: props?.currentPage ? props.currentPage : 1,
    apiUrl: window.maddy.ajax_url,
    action: 'get_listing',
    resultsContainer: null,
    clickedElement: null,
    mounted() {
      this.init();

      /**
       * App
       */
      class App extends Base {
        static config = {
          name: 'App',
          components: {
            Accordion,
          },
        };
      }

      const instance = createApp(App, document.querySelector('.archive-investors.l-container'));
      instance().then((app) => {
        this.appInstance = app;
      });
    },
    init() {
      this.$refs.filters.querySelectorAll('a').forEach((anchor) => {
        anchor.addEventListener('click', async (event) => {
          event.preventDefault();
          const checkbox = anchor.querySelector('.ep-checkbox');
          const isDisabled = anchor.getAttribute('aria-disabled') === 'true';

          if (!isDisabled) {
            checkbox.classList.toggle('checked');
          }

          this.onAnchorClick(anchor);
        });
      });
      this.fetchPosts();
    },
    openFilterMobile() {
      document.querySelector('body').classList.add('open-filter-panel');
    },
    closeFilterMobile() {
      document.querySelector('body').classList.remove('open-filter-panel');
    },
    onChange(e) {
      this.currentInputNode = e.target;
      this.currentInputChange = this.currentInputNode.name;

      if (this.currentInputChange === 'paged') {
        window.scroll({ top: this.$refs.form.offsetTop, left: 0, behavior: 'smooth' });
      }

      this.onFormUpdate();
    },
    submit(event) {
      event.preventDefault();
      this.onFormUpdate();
    },
    fetchPosts() {
      this.params = this.getParams();

      const currentResettableFilters = [];
      new URLSearchParams(this.params).forEach((value, key) => {
        if (resettableQueryParameters.includes(key)) {
          currentResettableFilters.push(key);
        }
      });
      this.hasFilters = currentResettableFilters.length > 0;

      this.loading = true;
      if (this.resultsContainer) {
        this.resultsContainer.classList.add('archive-investors-results--hide');
      }

      fetch(this.apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `action=${this.action}&security=custom_nonce&${this.params}`,
      })
        .then((response) => response.json())
        .then((response) => {
          this.$refs.containerCards.innerHTML = response.listing;
          this.$refs.pagination.innerHTML = response.pagination;
          this.$refs.count.innerHTML = response.count_html;
          this.$refs.countMobile.innerHTML = response.count_html;
          this.loading = false;
          if (this.view === 'table') {
            this.initSort();
          }
          this.resultsContainer = document.querySelector('.archive-investors-results');

          setTimeout(() => {
            this.resultsContainer.classList.remove('archive-investors-results--hide');
          }, 100);

          // For datalayer contentInteraction
          this.links = document.querySelectorAll('[data-link]');

          this.links.forEach((element) => {
            element.addEventListener('click', () => {
              this.clickedElement = element;
              this.sendDatalayer('contentInteraction', 0);
            });
          });

          this.sendDatalayer('results', response.count);
        });
    },
    getParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const entries = urlParams.entries();

      if (this.currentInputChange !== 'paged') {
        this.resetPagination();
      }

      let params = '';

      for (const entry of entries) {
        params += `${entry[0]}=${entry[1]}&`;
      }

      return params;
    },
    initSort() {
      document.querySelectorAll('.sorted-table').forEach((element) => {
        element.addEventListener('click', (e) => {
          this.sortBy(e.currentTarget.dataset.key);
        });
      });
    },
    sortBy(key) {
      const url = new URL(window.location.href);
      url.searchParams.delete('orderby');
      url.searchParams.delete('order');
      if (this.sortKey === key && this.order === 'ASC') {
        this.resetSorting();
        return;
      }

      if (this.sortKey === key) {
        this.order = 'ASC';
        this.isReverseSorted = true;
      } else {
        this.order = 'DESC';
        this.isReverseSorted = false;
      }

      this.sortKey = key;

      url.searchParams.set('orderby', this.sortKey);
      url.searchParams.set('order', this.order);
      this.updateUrl(url.href);
    },
    resetSorting() {
      const url = new URL(window.location.href);
      this.sortKey = '';
      this.order = 'DESC';
      this.isReverseSorted = false;
      this.sortedParams = '';
      url.searchParams.delete('orderby');
      url.searchParams.delete('order');
      this.updateUrl(url.href);
    },
    resetFilters() {
      const url = new URL(window.location.href);
      resettableQueryParameters.forEach((key) => {
        if (url.searchParams.has(key)) {
          url.searchParams.delete(key);
        }
      });

      this.updateUrl(url);

      this.$refs.filters.querySelectorAll('.ep-checkbox').forEach((checkbox) => {
        checkbox.checked = false;
      });

      document
        .querySelector('.archive-investors__filters')
        ?.querySelectorAll('.ep-checkbox')
        .forEach((checkbox) => {
          checkbox.checked = false;
        });

      document.querySelectorAll('input[name="query"]').forEach((input) => {
        input.value = '';
      });
    },
    onFormUpdate() {
      const formData = new FormData(this.$refs.form);
      const url = new URL(window.location.origin + window.location.pathname);
      const filters = Object.fromEntries(formData);

      formData.forEach((value, key) => {
        if (value) {
          url.searchParams.set(key, value);
        }
      });

      const checkboxParams = [];
      this.$refs.form.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
        if (checkbox.checked) {
          if (!checkboxParams[checkbox.name]) {
            checkboxParams[checkbox.name] = [];
          }
          checkboxParams[checkbox.name].push(checkbox.value);
        }
      });

      const allValues = [];

      Object.keys(checkboxParams).forEach((key) => {
        const valuesArray = checkboxParams[key];
        if (!allValues[key]) {
          allValues[key] = [];
        }
        allValues[key].push(...valuesArray);
      });

      Object.keys(allValues).forEach((key) => {
        filters[key] = allValues[key];
      });

      Object.keys(filters).forEach((key) => {
        if (filters[key] !== '') {
          url.searchParams.set(key, filters[key]);
        }
      });

      this.updateUrl(url.href);
    },
    async onAnchorClick(element) {
      const url = new URL(element.href);
      this.updateUrl(url.href);
    },
    resetPagination() {
      const url = new URL(window.location.href);
      url.searchParams.delete('paged');
    },
    updateUrl(baseUrl) {
      const url = new URL(baseUrl);
      window.history.replaceState(null, null, url);
      this.fetchPosts();
    },
    sendDatalayer(type, resultsNumber) {
      window.dataLayer = window.dataLayer || [];

      if (type === 'contentInteraction') {
        const contentInteractionData = this.clickedElement.parentNode.querySelector(
          '[data-datalayer="contentInteraction"]',
        ).textContent;

        window.dataLayer.push(JSON.parse(contentInteractionData));
        return;
      }

      if (this.currentInputChange === 'query') {
        window.dataLayer.push({
          event: 'search',
          keyword: this.currentInputNode.value,
          searchEngine: 'investors_search',
          resultsNumber,
        });
      }
      if (this.currentInputNode !== null && this.currentInputNode.type === 'checkbox') {
        window.dataLayer.push({
          event: 'interaction',
          category: 'Filter',
          action: this.currentInputNode.dataset.type,
          label: this.currentInputNode.dataset.name,
          value: this.currentInputNode.value,
        });
      }

      if (resultsNumber === 0) {
        return;
      }

      if (document.querySelector('[data-datalayer="contentImpression"]')) {
        const contentImpressionItems = document.querySelector(
          '[data-datalayer="contentImpression"]',
        ).textContent;

        window.dataLayer.push({
          event: 'contentImpression',
          contentImpressionItems: JSON.parse(contentImpressionItems),
        });
      }
    },
  };
}
