/**
 * Contact Form.
 * @param {any} props
 * @returns {any}
 */
export default function ContactForm(props) {
  return {
    state: {
      toggled: !!props && props.toggled,
      element: {
        successContainer: document.querySelector('.js-success-message'),
        failureContainer: document.querySelector('.js-failure-message'),
      },
      data: {
        ajaxUrl: props?.ajaxUrl ? props.ajaxUrl : `${window.maddy.ajax_url}?action=sendContact`,
        selector: '.js-form-contact',
      },
    },
    async sendForm(event) {
      event.preventDefault();
      this.$refs.contactForm.style.display = 'block';
      this.$refs.failureMessage.style.display = 'none';
      this.$refs.successMessage.style.display = 'none';
      let url = this.state.data.ajaxUrl;

      if (document.documentElement.lang === 'en-UK' && !url.startsWith('/uk/')) {
        url = `/uk${this.state.data.ajaxUrl}`;
      }

      try {
        await fetch(url, {
          method: 'POST',
          body: new FormData(this.$refs.contactForm),
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Bad response');
          }
        });

        this.onFormSuccess();
      } catch {
        this.onFormFailure();
      }
    },
    onFormSuccess() {
      this.$refs.contactForm.style.display = 'none';
      this.$refs.failureMessage.style.display = 'none';
      this.$refs.successMessage.style.display = 'block';
    },
    onFormFailure() {
      this.$refs.failureMessage.style.display = 'block';
      this.$refs.successMessage.style.display = 'none';
    },
    onClickSendFormError() {
      this.$refs.contactForm.className += ' validate';
    },
    init() {
      // Silence is golden.
    },
    mounted() {
      this.init();
    },
  };
}
