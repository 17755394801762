import Choices from 'choices.js';

/**
 * input-select.js
 * @returns {object}
 */
export default function InputSelect() {
  return {
    choicesInstance: null,
    init() {
      this.choicesInstance = new Choices(this.$refs.control, {
        itemSelectText: '',
      });
    },
    mounted() {
      this.init();
    },
  };
}
