/**
 * /sticky-investor.js
 * @returns {object}
 */
export default function StickyInvestor() {
  return {
    el: null,
    targetElement: document.querySelector('.investors-contact-banner'),
    observer: null,
    options: {
      treshold: 0.5,
    },
    mounted() {
      /* eslint-disable no-unused-vars */
      this.observer = new IntersectionObserver(this.handleIntersection.bind(this), this.options);
      this.observer.observe(this.targetElement);
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.el.classList.add('hide');
        } else {
          this.el.classList.remove('hide');
        }
      });
    },
  };
}
