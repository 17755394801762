/**
 * toggleDouble
 * @param {*} props
 * @returns {object}
 */
export default function toggleDouble(props) {
  return {
    state: {
      toggled: props ? props.toggled : false,
    },
    toggle() {
      if (this.state.toggled) {
        this.toggleOff();
      } else {
        this.toggleOn();
      }
    },
    toggleOn() {
      this.state.toggled = true;
    },
    toggleOff() {
      this.state.toggled = false;
    },
  };
}
