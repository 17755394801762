import { displayPopup } from '../../js/utils/display-popup.js';

/**
 * Social bar.
 * @returns {unknown}
 */
export default function SocialsBar() {
  return {
    body: document.querySelector('body'),
    progress: null,
    popupWidth: 550,
    popupHeight: 410,
    init() {
      this.updateProgress();
      window.addEventListener('scroll', this.updateProgress.bind(this));
    },
    updateProgress() {
      this.progress = (window.scrollY / (this.body.scrollHeight - window.innerHeight)) * 100;
    },
    displayPopup,
    mounted() {
      this.init();
    },
  };
}
