import { breakpoints } from '../../js/utils/breakpoints.js';

/**
 * header-search.js
 * @returns {object}
 */
export default function headerSearch() {
  const matchMobile = window.matchMedia(`(max-width: ${breakpoints['$bp-lg']})`);
  return {
    state: {
      isMobile: matchMobile.matches,
      isOpened: false,
    },
    mounted() {
      matchMobile.addListener(() => {
        this.state.isMobile = matchMobile.matches;
      });
    },
    toggle() {
      this.state.isOpened = !this.state.isOpened;
    },
  };
}
