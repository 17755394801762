import { displayPopup } from '../utils/display-popup.js';
/**
 * Load in short.
 * @param {any} e
 * @returns {any}
 */
export default function LoadInShort(e) {
  return {
    state: {
      news_of_the_day: e?.news_of_the_day || [],
      breves: e?.breves || [],
      nextPage: e?.nextPage || 2,
      query: '',
    },
    path: '/in-short/',
    async loadNext() {
      try {
        await fetch(
          `${this.path}?paged=${this.state.nextPage}&query=${this.state.query}&isAjaxRequest`,
        )
          .then((response) => response.json())
          .then((data) => {
            this.onSuccess(data);
          });
      } catch {
        // console.log(error);
      }
    },
    onSuccess(data) {
      this.state.breves = [...this.state.breves, ...(data?.breves || [])];
      this.state.nextPage = data?.nextPage;
    },
    async onSubmit(event) {
      event.preventDefault();
      this.state.query = this.$refs.searchForm.value;
      await this.sendQuery();
    },
    displayPopup,
    async sendQuery() {
      try {
        await fetch(`${this.path}?query=${this.state.query}&isAjaxRequest`)
          .then((response) => response.json())
          .then((data) => {
            this.onSuccessQuery(data, !0);
          });
      } catch {
        // console.log(error);
      }
    },
    onSuccessQuery(data) {
      this.state.news_of_the_day = data?.news_of_the_day || [];
      this.state.breves = data?.breves || [];
      this.state.nextPage = data?.nextPage;
    },
    init() {
      // Silence is golden.
    },
    mounted() {
      this.init();
    },
  };
}
