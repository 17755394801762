/**
 * Outil loader.
 * @param {any} props
 * @returns {any}
 */
export default function OutilLoader(props) {
  return {
    state: {
      outil: props.outil || [],
      query: {
        s: '',
        theme: '',
        sort: '',
        paged: 1,
      },
      nextPage: 2,
    },
    path: '/100-outils-pour-reussir/?isAjaxRequest=1&',
    async changeFilter(event = null) {
      if (event?.detail?.type) {
        this.state.query[event.detail.type] = event.detail.newValue;
      }
      this.state.query.paged = 1;
      this.state.nextPage = 2;
      await this.getIncubatorItems();
    },
    async getIncubatorItems() {
      const query = Object.keys(this.state.query)
        .reduce((acc, key) => {
          const value = this.state.query[key];
          if (value && ((typeof value === 'string' && value.length) || typeof value === 'number')) {
            acc.push(`${key}=${value}`);
          }
          return acc;
        }, [])
        .join('&');
      try {
        await fetch(String(this.path) + query)
          .then((response) => response.json())
          .then((data) => {
            if (data.loadNextPage && data.loadNextPage === this.state.nextPage) {
              this.state.incubators = data.posts;
            } else {
              this.state.incubators = [...this.state.incubators, ...data.posts];
            }

            if (data.loadNextPage) {
              this.$refs.loadNextPage.style.display = 'block';
              this.state.nextPage = data.loadNextPage;
            } else {
              this.$refs.loadNextPage.style.display = 'none';
            }
          });
      } catch {
        // console.log(error);
      }
    },
    async onClickSearch(event) {
      event.preventDefault();
      this.state.query.s = this.$refs.searchInput.value;
      await this.changeFilter();
    },
    async onClickLoadMore() {
      this.state.query.paged = this.state.nextPage;
      await this.getIncubatorItems();
    },
    /**
     * @todo Replace with URLSearchParams.
     * @param   {any} obj
     * @returns {string}
     */
    serialize(obj) {
      const params = [];

      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }

      return params.join('&');
    },
    init() {
      document
        .querySelector('.filters')
        .addEventListener('filters:change', (event) => this.changeFilter(event));
    },
    mounted() {
      this.init();
    },
  };
}
