import tippy from 'tippy.js';

/**
 * Select
 * @param {any} props
 * @returns {any}
 */
export default function Select(props) {
  return {
    $template: '#select-filter',
    el: null,
    instance: null,
    state: {
      ...props,
    },
    get value() {
      const url = new URL(window.location.href);
      return (
        (url.search.replace('?', '').split('&') || []).forEach((t) => {
          if (this.state.query && t.split('=')[0] === this.state.query) {
            const option = this.state?.options.find((opt) => opt.id === t.split('=')[1]);

            return (
              option && (this.state.value = option.id.toString()),
              (
                option ||
                // eslint-disable-next-line no-unsafe-optional-chaining
                this.state?.options.find(
                  (opt) => opt.id.toString() === this.state?.value.toString(),
                )
              ).value
            );
          }
        }),
        this.state?.options.find((e) => e.id.toString() === this.state?.value.toString()).value
      );
    },
    chooseItem(option) {
      this.state.value = option.id;
      option.name = this.state.label;
      option.type = this.state.type;
      const event = new CustomEvent('select-filter:change', {
        bubbles: !0,
        detail: option,
      });
      if ((this.el.dispatchEvent(event), this.instance.hide(), this.state.query)) {
        const url = new URL(window.location.href);
        const i = url.search.length
          ? (url.search.replace('?', '').split('&') || []).reduce((e, t) => {
              const tSplit = t.split('=');

              e[tSplit[0]] = tSplit[1];
              return e;
            }, {})
          : {};
        i[this.state.query] = option.id;
        const query = Object.entries(i)
          .reduce((e, [t, s]) => {
            e.push(`${t}=${s}`);
            return e;
          }, [])
          .join('&');
        window.location.href = `${url.origin + url.pathname}?${query}`;
      }
    },
    init() {
      const e = this.$refs.trigger;
      const t = this.$refs.dropdown;
      this.instance = tippy(e, {
        content: t,
        placement: 'bottom-end',
        trigger: 'click',
        offset: [0, 8],
        interactive: !0,
        delay: 100,
        zIndex: 10,
        popperOptions: {
          strategy: 'fixed',
          modifiers: [
            {
              name: 'flip',
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: !1,
                tether: !1,
              },
            },
          ],
        },
      });
    },
    mounted() {
      this.init();
    },
  };
}
