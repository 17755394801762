/**
 * ClickCounter
 */
class ClickCounter {
  selector = 'a[data-click-counter]';

  /**
   * Init
   */
  init() {
    const linkElements = document.querySelectorAll(this.selector);
    this.handleClick = this.handleClick.bind(this);

    this.ajaxUrl = new URL(window.maddy.ajax_url);
    this.ajaxUrl.searchParams.append('action', 'incrementBlocButton');
    this.ajaxUrl.searchParams.append('post_id', this.postId);

    linkElements.forEach((el) => el.addEventListener('click', this.handleClick));
  }

  /**
   * HandleClick
   * @param {MouseEvent} e
   */
  async handleClick(e) {
    const el = e.currentTarget;

    if (!(el instanceof HTMLAnchorElement)) {
      return;
    }

    const isSameTab =
      (!el.target || el.target === '_self') &&
      e.button === 0 &&
      !e.metaKey && // open in new tab (mac)
      !e.ctrlKey; // open in new tab (windows);

    if (isSameTab) {
      e.preventDefault();
    }

    const url = el.href;
    this.ajaxUrl.searchParams.set('url', url);

    await fetch(this.ajaxUrl.toString(), {
      method: 'GET',
    });

    if (!isSameTab) {
      return;
    }

    window.location = url;
  }

  /**
   * Get post id.
   * @returns {int}
   */
  get postId() {
    const bodyClasses = document.body.classList.value;
    const result = bodyClasses.match(/postid-(\d+)/);
    return result ? result[1] : 0;
  }
}

window.addEventListener('load', () => {
  new ClickCounter().init();
});
