import Swiper from 'swiper/bundle';

/**
 * shortListCarousel.j
 * @returns {object}
 */
export default function shortListCarousel() {
  return {
    instance: null,
    init() {
      const target = this.$refs.carousel || null;
      const options = {
        slidesPerView: 1,
        spaceBetween: 40,
        grabCursor: true,
        loop: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 80,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 92,
          },
        },
      };

      this.instance = new Swiper(target, options);
    },
    prevItem() {
      this.instance.slidePrev();
    },
    nextItem() {
      this.instance.slideNext();
    },
    mounted() {
      this.init();
    },
  };
}
