/**
 * Add target="_blank" to external links
 */
export default function externalLinksToNewTab() {
  const anchors = document.querySelectorAll('a');

  anchors.forEach((anchor) => {
    const href = anchor.getAttribute('href')?.trim();

    if (!href || anchor.dataset.ignoreTarget === '') {
      return;
    }

    if (href.startsWith(window.location.origin) || href.startsWith('/') || href.startsWith('#')) {
      return;
    }

    const currentTarget = anchor.getAttribute('target');
    if (currentTarget === '_blank') {
      return;
    }

    anchor.setAttribute('target', '_blank');
  });
}
