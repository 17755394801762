/**
 * Display an anchor links in a popup.
 * @param   {MouseEvent} event
 * @returns {void}
 */
export function displayPopup(event) {
  event.preventDefault();
  const target = event.target.getAttribute('href');
  const left = window.screenLeft ?? window.screen.left;
  const top = window.screenTop ?? window.screen.top;
  const width = window.innerWidth ?? document.documentElement.clientWidth ?? window.screen.width;
  const height =
    window.innerHeight ?? document.documentElement.clientHeight ?? window.screen.height;
  const popup = window.open(
    target,
    'Share',
    `scrollbars=yes, width=550, height=410, top=${height / 2 - 275 + top}, left=${
      width / 2 - 275 + left
    }`,
  );

  if (window.focus) {
    popup.focus();
  }
}
