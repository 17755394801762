const breakpoints = {
  '$bp-xs': '360px',
  '$bp-sm': `${576 - 1}px`,
  '$bp-md': `${768 - 1}px`,
  '$bp-lg': `${992 - 1}px`,
  '$bp-xl': `${1200 - 1}px`,
  '$bp-xxl': `${1400 - 1}px`,
};

export { breakpoints };
