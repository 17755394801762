import Swiper from 'swiper/bundle';

/**
 * playCarouse
 * @param {*} props
 * @returns {object}
 */
export default function playCarousel(props) {
  return {
    instance: null,
    init() {
      const target = this.$refs.carousel || null;

      const navigation =
        this.$refs.prev && this.$refs.next
          ? {
              navigation: {
                prevEl: this.$refs.prev || null,
                nextEl: this.$refs.next || null,
              },
            }
          : undefined;

      const options = {
        spaceBetween: 24,
        slidesPerView: 1,
        centeredSlides: true,
        ...navigation,
        breakpoints: {
          576: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 3,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 4,
            centeredSlides: false,
          },
        },
        ...props?.options,
      };

      this.instance = new Swiper(target, options);
    },
    mounted() {
      this.init();
    },
  };
}
