/**
 * modal.js
 * @param {*} props
 * @returns {object}
 */
export default function modal(props) {
  return {
    $template: props?.template,
    state: null,
    isOpen: false,
    get classList() {
      return {
        'is-show': this.isOpen,
      };
    },
    init() {
      this.$store.modals.push(this);
    },
    hide() {
      this.state = null;
      this.isOpen = false;
    },
    show(value) {
      this.state = value;
      if (typeof this.state.onOpen === 'function') {
        this.state.onOpen(this.$refs.container);
      }
      this.isOpen = true;
    },
    mounted() {
      this.init();
    },
  };
}
