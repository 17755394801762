import Plyr from 'plyr';

/**
 * articlePodcast.js
 * @returns {object}
 */
export default function articlePodcast() {
  return {
    el: null,
    player: null,
    initPlayer() {
      this.player = new Plyr(this.$refs.player);
      this.player.on('ready', () => {
        this.el.classList.add('is-loaded');
        this.player.play();
      });
    },
  };
}
