import scriptLoader from '../utils/scriptLoader.js';
import { getCookie } from '../utils/cookies.js';
/**
 * Poool
 */
(() => {
  // Poool debug
  const debug = false;

  if (document.documentElement.lang === 'en-UK') {
    return;
  }

  // Poool application Id and app name
  const pooolAppId = 'Ux3eWdmyaRN4vgVq60rsLRVZu0KCWfNGTVyZ0B74H7CQwkQOekXVg43zcoYyd8YM';
  const appName = 'Maddyness';

  // TODO: reactivate later
  // if (window.location.host !== 'www.maddyness.com') {
  //   // Rewrite on preprod and local
  //   pooolAppId = 'DXyUXa1hJPv7ymrrFPR3Uvjpt1g70tDtUg5VlIU4kUj2AyHehuyAsY3JS7r6m1Td';
  //   appName = 'Maddyness - Preprod';
  // }

  // Poool subscribe urls
  const subsUrls = {
    subscribe: 'https://abonnement.maddyness.com/?returnUrl={return_url}',
    signin: 'https://abonnement.maddyness.com/me?returnUrl={return_url}',
    account: 'https://abonnement.maddyness.com/me',
  };

  // Safe contexts (paywall hidden on errors and no content blocking)
  const safeContexts = new Set(['homepage']);

  // Vendor slugs on Axeptio
  const axeptioVendor = {
    poool: 'poool',
    ga: 'google_analytics',
  };

  // Consent status
  const hasConsent = (vendor) => getCookie('axeptio_authorized_vendors')?.includes(vendor) ?? false;
  const currentConsents = {
    poool: hasConsent(axeptioVendor.poool),
    ga: hasConsent(axeptioVendor.ga),
  };

  // Declare all instance var
  let audit = null;
  let subs = null;
  let access = null;
  let engage = null;

  // Declare user var
  let user = null;

  // Subs Target element (for auth injection)
  const subsTargets = {
    auth: Array.from(document.querySelectorAll('[data-poool-auth]')),
    signin: Array.from(document.querySelectorAll('[data-poool-signin]')),
    subscribe: Array.from(document.querySelectorAll('[data-poool-subscribe]')),
    signout: Array.from(document.querySelectorAll('[data-poool-signout]')),
    account: Array.from(document.querySelectorAll('[data-poool-account]')),
  };

  // Access Target element (for paywall injection)
  const accessTarget = document.querySelector('[data-poool]');

  // Access Content element (to hide)
  const accessContent = document.querySelector('[data-poool-content]');

  // Poool Context
  const context =
    accessTarget?.dataset?.poool && accessTarget.dataset.poool.length > 0
      ? accessTarget.dataset.poool
      : null;

  // Poool Page type
  let pageType = context ? 'premium' : 'free';
  pageType =
    accessTarget?.dataset?.type && accessTarget.dataset.type.length > 0
      ? accessTarget.dataset.type
      : pageType;

  const baseConfig = {
    debug,
    user_is_premium: false,
    cookies_enabled: currentConsents.poool,
    gtag_auto_tracking_enabled: currentConsents.ga,
    auto_tracking_spec_v2: currentConsents.ga,
  };

  // Poool Audit init
  const initAudit = () => {
    // eslint-disable-next-line
    if (audit || !Audit) {
      return;
    }

    // eslint-disable-next-line
    audit = Audit.init(pooolAppId);
    audit.config(baseConfig);

    if (context && context !== 'default') {
      audit.config('context', context);
    }

    if (user) {
      audit.config('user_is_premium', user && user.subscription && user.subscription.active);
    }

    audit.sendEvent('page-view', { type: pageType });
  };

  // Poool Access init
  const initAccess = () => {
    // eslint-disable-next-line
    if (access || !Access || !accessTarget) {
      return;
    }

    if (user && user.subscription && user.subscription.active) {
      accessTarget.dataset.unlock = '';
      return;
    }

    // eslint-disable-next-line
    access = Access.init(pooolAppId);
    delete accessTarget.dataset.unlock;

    if (pageType === 'free') {
      accessTarget.dataset.unlock = '';
    }

    let paywall = {
      target: accessTarget,
      pageType,
    };

    access.config({
      ...baseConfig,
      paywall_load_timeout: 30000,
      app_name: appName,
      default_widget: 'gift',
      subscription_url: subsUrls.subscribe,
      login_url: subsUrls.signin,
      disable_content_height_calculation: true,
    });

    if (context && context !== 'default') {
      access.config('context', context);
    }

    if (accessContent) {
      let percent = Number.parseInt(accessTarget.dataset.percent, 10);

      if (Number.isNaN(percent) || percent <= 0) {
        percent = 90;
      }

      paywall = {
        ...paywall,
        content: accessContent,
        mode: 'excerpt',
        percent,
      };
    }

    access.styles({
      layout: 'landscape',
      brand_logo: null,
      button_color: '#000000',
      button_hover_color: '#3e68ff',
      skin_color: '#F5F4EE',
      premium_color: '#3e68ff',
      custom_css: `
      @import url('https://applidget.github.io/vx-assets/orgs/madyness/fonts/stylesheet.css');

      :root {
        --color-primary: #eeff8a;
        --color-border: #000;
        --font-body: Agrandir, "Open Sans", Helvetica, Arial, sans-serif;
      }

      body {
        font-family: "Agrandir", sans-serif;
        font-weight: 400;
        letter-spacing: 0.01em;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper {
        background-color: var(--color-primary);
        border: 7px solid var(--color-border);
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container {
        max-width: 640px;
      }

      .p3-widget .p3-wrapper .p3-inner .p3-container {
        padding: 3.5rem;
      }

      .p3-widget-newsletter form.p3-form {
        width: 100%;
        max-width: unset;
        display: grid;
        gap: 10px;
        grid-template-areas:
          "field field field button"
          "error error error error"
          "optin optin optin optin";
      }

      .p3-widget-newsletter form.p3-form .junipero.field.text-input {
        grid-area: field;
      }

      .p3-widget-newsletter form.p3-form .junipero.field.text-input input {
        background: white;
        border-radius: 0;
        border: 0;
        border-bottom: 5px solid rgb(62, 104, 255);
        transition: background-color .2s, border-color .2s;
      }

      .p3-widget-newsletter form.p3-form .junipero.field.text-input input:focus {
        background: white;
        border-color: black;
      }

      .p3-widget-newsletter form.p3-form .junipero.field.text-input.dirty:not(.has-error) input:not(:focus) {
        background: white;
        border-color: rgb(62, 104, 255);
      }

      .p3-widget-newsletter form.p3-form .p3-optin {
        grid-area: optin;
        display: block;
        margin-bottom: 0;
        margin-top: 0;
      }

      .p3-widget-newsletter form.p3-form .p3-optin .junipero.field.checkbox {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 0;
      }

      .p3-widget-newsletter form.p3-form .p3-optin .junipero.field.checkbox .content {
        font-size: 13px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.7);
      }

      .p3-widget-newsletter form.p3-form .p3-optin .p3-data-policy {
        padding-left: 26px;
        max-width: unset;
      }

      .p3-widget-newsletter .p3-full-widget .p3-button-wrapper {
        grid-area: button;
        margin-bottom: 0;
        margin-top: 0;
      }

      .p3-widget-newsletter form.p3-form .p3-alert {
        grid-area: error;
      }

      .p3-widget-form.p3-layout-landscape form.p3-form, .p3-widget-newsletter.p3-layout-landscape form.p3-form, .p3-widget-pass.p3-layout-landscape form.p3-form {
        margin: 0;
        width: auto;
      }

      .p3-widget-form.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy, .p3-widget-newsletter.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy, .p3-widget-pass.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy {
        margin-left: 0;
      }

      a.p3-button, button.p3-button, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        padding: 8px 32px;
        font-family: "Agrandir", sans-serif;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        background-color: #fff;
        color: black;
        border: 1px solid black;
        border-radius: 0;
        box-shadow: 5px 5px #3e68ff;
        transition: background-color .2s, color .2s;
        text-shadow: none;
      }

      a.p3-button:hover, button.p3-button:not(:disabled):hover, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action:not(:disabled):hover {
        color: #fff;
        text-decoration: none;
        background-color: #3e68ff;
        box-shadow: 5px 5px #000;
      }

      button.p3-button:disabled, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action:disabled {
        background-color: #fff;
        text-shadow: none;
        cursor: not-allowed;
      }

      .p3-widget.p3-layout-landscape a.p3-button, .p3-widget.p3-layout-landscape button.p3-button {
        font-size: 13px;
        box-shadow: 5px 5px #3e68ff;
        margin: 0 0 16px 0;
      }

      .p3-widget.p3-layout-landscape a.p3-button:hover, .p3-widget.p3-layout-landscape button.p3-button:not(:disabled):hover {
        box-shadow: 5px 5px #000;
      }

      .p3-widget-form .p3-wrapper .p3-inner .p3-container button.p3-main-action, .p3-widget-newsletter .p3-wrapper .p3-inner .p3-container button.p3-main-action, .p3-widget-pass .p3-wrapper .p3-inner .p3-container button.p3-main-action {
        width: auto;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link {
        border: 0;
        flex-grow: unset;
        width: auto;
        text-decoration: underline;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-action .p3-button, .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions .p3-button {
        width: auto;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link:hover {
        background: transparent;
        color: #3e68ff;
        text-decoration: none;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-action, .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions {
        width: auto;
        margin-top: 1rem;
      }

      .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link.p3-subscribe:hover {
        border: 0 !important;
        background: transparent;
        color: #3e68ff;
      }

      .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action {
        margin-bottom: 5px;
      }

      .p3-widget .p3-wrapper .p3-inner .p3-container h3.p3-title {
        margin-bottom: 12px;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
      }

      .p3-widget .p3-wrapper .p3-inner .p3-container p.p3-text {
        margin-bottom: 38px;
        font-size: 24px;
        line-height: 29px;
      }
          `,
    });

    access.on('release', () => {
      accessTarget.dataset.unlock = '';
    });

    access.on('error', (error, event) => {
      if (safeContexts.has(context) || pageType !== 'premium') {
        event.forceRelease();
      }
    });

    access.createPaywall(paywall);
  };

  const updateAuthElements = () => {
    const isLogged = !!user;
    const isActive = isLogged && user.subscription && user.subscription.active;

    const {
      signin: signinTargets,
      subscribe: subscribeTargets,
      signout: signoutTargets,
      account: accountTargets,
    } = subsTargets;

    signoutTargets.forEach((signoutTarget) => {
      signoutTarget.style.display = isLogged ? 'block' : 'none';
      if (!isLogged) {
        signoutTarget.setAttribute('disabled', '');
      } else {
        signoutTarget.removeAttribute('disabled');
      }
    });

    accountTargets.forEach((accountTarget) => {
      accountTarget.style.display = isLogged ? 'block' : 'none';
    });

    signinTargets.forEach((signinTarget) => {
      signinTarget.style.display = !isLogged ? 'block' : 'none';
    });

    subscribeTargets.forEach((subscribeTarget) => {
      subscribeTarget.style.display = !isActive ? 'block' : 'none';
    });
  };

  const initEngage = () => {
    // eslint-disable-next-line no-undef
    if (engage || !Engage) {
      return;
    }

    // eslint-disable-next-line no-undef
    engage = Engage.init(pooolAppId);
    engage.config({
      debug,
      gtagAutoTrackingEnabled: currentConsents.ga,
    });
    engage.autoCreate({
      filters: [
        user && user.subscription && user.subscription.active
          ? 'user_subscribed'
          : 'user_unsubscribed',
      ],
    });
  };

  const loadScripts = () => {
    scriptLoader('https://assets.poool.fr/audit.min.js', initAudit);
    scriptLoader('https://assets.poool.fr/access.min.js', initAccess);
    scriptLoader('https://assets.poool.fr/engage.min.js', initEngage);
  };

  const updateScripts = () => {
    const isActive = !!user && user.subscription && user.subscription.active;

    if (isActive && access) {
      if (accessTarget) {
        accessTarget.dataset.unlock = '';
      }

      access.destroy();
      access = null;
      accessContent.removeAttribute('style');
      accessTarget.innerHTML = '';
      accessContent.poool.locked = false;
    } else {
      initAccess();
    }

    audit.config('user_is_premium', isActive);
  };

  // Poool Subscribe init
  const initSubs = async () => {
    // eslint-disable-next-line
    subs = Subscribe.init(pooolAppId);

    const {
      auth: authTargets,
      signin: signinTargets,
      subscribe: subscribeTargets,
      signout: signoutTargets,
      account: accountTargets,
    } = subsTargets;

    const currentUser = await subs.getUser();

    user = currentUser ?? null;

    updateAuthElements();
    loadScripts();

    authTargets.forEach((authTarget) => {
      subs.createAuthElement('signin-with-subscribe-button', {
        target: authTarget,
      });
    });

    accountTargets.forEach((accountTarget) => {
      accountTarget.href = subsUrls.account;
    });

    signinTargets.forEach((signinTarget) => {
      signinTarget.href = subsUrls.signin.replace('{return_url}', window.location.toString());
    });

    subscribeTargets.forEach((subscribeTarget) => {
      subscribeTarget.href = subsUrls.subscribe.replace('{return_url}', window.location.toString());
    });

    signoutTargets.forEach((signoutTarget) => {
      signoutTarget.addEventListener('click', async () => {
        user = null;
        updateAuthElements();
        updateScripts();
        subs.signout();
      });
    });
  };

  setTimeout(() => {
    // eslint-disable-next-line no-undef
    if (__tcfapi) {
      // eslint-disable-next-line no-undef
      __tcfapi('addEventListener', 2, (tcData, success) => {
        if (!success || tcData.eventStatus !== 'useractioncomplete') {
          return;
        }

        const consentStatus =
          tcData?.purpose?.consents?.['2'] &&
          tcData?.purpose?.consents?.['3'] &&
          tcData?.purpose?.consents?.['4'] &&
          tcData?.purpose?.consents?.['5'] &&
          tcData?.purpose?.consents?.['6'] &&
          tcData?.purpose?.consents?.['7'] &&
          tcData?.specialFeatureOptins?.['1'] &&
          tcData?.specialFeatureOptins?.['2'];

        if (
          consentStatus === currentConsents.poool &&
          hasConsent(axeptioVendor.poool) === currentConsents.poool
        ) {
          return;
        }

        window.location.reload();
      });
    }
  }, 1000);

  // Load Poool Subscribe script
  scriptLoader('https://assets.poool-subscribe.fr/subscribe.js', initSubs);
})();
