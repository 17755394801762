/**
 * inputFile
 * @returns {object}
 */
export default function inputFile() {
  return {
    label: null,
    input: null,
    mounted() {
      this.$refs.input.addEventListener('change', (event) => {
        this.$refs.label.innerHTML = event.target.files[0].name;
      });
    },
  };
}
